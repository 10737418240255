import { Component, OnInit ,HostListener ,EventEmitter ,Input ,Output } from '@angular/core';
import { Category ,CategoryItem, AboutRestaurant, FoodItem} from 'src/app/models/category';
import { ChangeFood,OrderAdditon , RestaurantItem, Restaurant, CategoryRestaurant} from 'src/app/models/restaurant'
import { Router ,ActivatedRoute} from '@angular/router';
import { AppProxy } from 'src/app/services/app-proxy';
import { BussinssService } from 'src/app/services/bussinss-service';
import { OrdersService } from 'src/app/services/order-service';
import { MyOrder, OrderAdding } from 'src/app/models/order';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { AppComponent } from 'src/app/app.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BigImageComponent } from '../big-image/big-image.component';
import { DataService } from 'src/app/services/data.service';
import { PopupOrderComponent } from '../popup-order/popup-order.component';
import { DialogOutOfBoundsComponent } from '../dialog-out-of-bounds/dialog-out-of-bounds.component';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css']
})
export class RestaurantComponent implements OnInit {
  @Output()changePrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changeShowPrice: EventEmitter<number> = new EventEmitter<number>();
  @Output()changByFood: EventEmitter<number> = new EventEmitter<number>();
  @Output()changText: EventEmitter<number> = new EventEmitter<number>();
  stars:number[]=[1,2,3,4,5];
  aboutRestaurant:AboutRestaurant[];
  categores:CategoryRestaurant[];
  categoresItem:CategoryItem[];
  restaurantItem:RestaurantItem[]=[];
  changeFood:ChangeFood[];
  toppingsDish:OrderAdditon[];
  foodItem:FoodItem[];
  showChangeFood:boolean=false;
  aboutRestaurantCheck='assets/images/Circle_On.png';
  buttonText="העגלה ריקה";
  totalItems:number=0;
  totalAmount:number=0.0;
  amountFood:number[];
  restaurant:Restaurant=new Restaurant;
  order:MyOrder;
  id:number;
  orderAddingList:OrderAdditon[];
  Rate= new Array<number>(3);
  clickFevorite=false;
  fevorit='assets/images/Restorant_Page_Fevorit_Button.png';
  fevoritActive='assets/images/Restorant_Page_Fevorit_Button_On.png';
  Name:string="";
  priceTotal="0 - פריטים"
  price=0;
  clickToPay="לחץ לתשלום - "
  categoryToFilter="";
  foodName:string;
  dayTime="סגור";
  num=0;
  page:number=1;
  sum = 100;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  text:string;
  Image:string="";
  orderOpen:boolean=false;
  endToppings=true;
  categoryFilter=0;
  showProgress=false;
  show=true;
  animal: string;
  name: string;
  @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.onBackPress();
  // }

  // continue(){
  //   this.proxy.restaurant=this.restaurant;
  //   this.router.navigate(["../yourOrder"])
  // }

  // onBackPress()
  // {
  //   //localStorage.ID=localStorage.ID_CATEGORY;
  //    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
  //      this.order=res;
  //     if(this.order!=null && this.order.list.length==0)
  //     {
  //       this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
  //         null,null,0,null,1,null,null,null,null,null,null,null,null).subscribe((res:any)=>{
  //   this.router.navigate(["../restaurantCategory"]);
  //         });
  //       }
  //    });
  // }

  ngOnInit(): void {
    {
      this.scrollToTop();
      this.dataService.byFood.next(true);
      this.changByFood.emit();
      this.dataService.show.next(true);
      this.changeShowPrice.emit();
      this.dataService.showSearch.next(true);
      this.changByFood.emit();
      this.orderOpen = this.dataService.orderOpen.getValue() || {};
    this.dataService.orderOpen.subscribe(data=>{
      this.orderOpen=data;
  if(this.orderOpen=true && this.id!=undefined)
  {
    this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
        this.restaurant=res;
        this.categores=this.restaurant.categoryList;
        // this.categoryToFilter=this.categores[0].Category;
        // this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
        // this.restaurant=res;
        // this.categores=this.restaurant.categoryList;
        if(this.restaurant.LongImage!=null)
        this.Image=this.restaurant.LongImage;
        if(this.restaurant.MenuList.length>0)
       // {
          this.restaurantItem=this.restaurant.MenuList;
          this.orderService.getOrderDetails(false).subscribe((res:any)=>{
            this.order=res;
      this.priceTotal="0 - פריטים";
      this.totalItems=0;
      this.totalAmount=0;
  localStorage.PRICE_TOTAL=this.priceTotal;
     this.dataService.price.next(this.priceTotal);
     this.changePrice.emit();
  //  });
  });
  });
  }
    });
      localStorage.SHOW=true;
   localStorage.PRICE_TOTAL=this.priceTotal;
    if(this.bussinssService.restaurant==null)
    {
      localStorage.CATEGORY_FILTER=0;
      this.orderService.orderAddingList=null;
      if(localStorage.ID!="0")
    if(this.categoryToFilter=="")
      this.id=localStorage.ID;
      else
      this.id=this.restaurant.ID;
      if(this.categoryToFilter==null)
      this.categoryToFilter="";
      if(this.id!=undefined)
    this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
    this.restaurant=res;
    this.categores=this.restaurant.categoryList;
    this.categoryToFilter=this.categores[0].Category;
    //this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
   //   this.restaurant=res;
    if(this.restaurant.LongImage!=null)
    this.Image=this.restaurant.LongImage;
 //   if(this.restaurant==null)
//{
 // localStorage.ID_CATEGORY= localStorage.ID;
  //this.proxy.showError("שגיאה בקבלת פרטים ","");
//}
//else
{
  this.aboutRestaurant=[
    {
      "aboutRestaurantId": 1,
      "aboutRestaurantName":"בשרי",
      "isCheck": this.restaurant.IsMeaty,
    },
    {
      "aboutRestaurantId": 2,
      "aboutRestaurantName": "אסייתי",
      "isCheck": this.restaurant.IsAsian,
    },
    {
      "aboutRestaurantId": 3,
      "aboutRestaurantName":  "כשר",
      "isCheck": this.restaurant.Kasher,
    },
  ];
   if(this.restaurant.MenuList!=null)
    this.restaurantItem=this.restaurant.MenuList;
    
    for(let i=0;i<this.categores.length;i++)
    {
      this.categores[i].isCheck=0;
      if(this.categoryToFilter!="" && this.categoryToFilter!=null)
      if(this.categores[i].Category==this.categoryToFilter)
      this.categores[i].isCheck=1;
    }
   
    if(this.restaurantItem!=null)
    for(let i=0;i<this.restaurantItem.length;i++)
      {
        if(this.restaurantItem[i].Rate==undefined)
        this.restaurantItem[i].Rate=0;
        else if(this.restaurantItem[i].Rate>0)
        {
          this.buttonText="המשך לתשלום";
        this.totalItems+=this.restaurantItem[i].Rate;
       let p=0.0;
       p=this.restaurantItem[i].Price;
       this.totalAmount=Number(this.totalItems)*Number(p);
       if(this.totalAmount>0)
    this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
    else
    this.priceTotal="0 - פריטים";
    this.dataService.price.next(this.priceTotal);
    this.changePrice.emit();
    localStorage.PRICE_TOTAL=this.priceTotal;
        }
      }}
      if(this.restaurant!=null)
      {
      //  if(this.restaurant.categoryList!=null)
      //  this.categores=this.restaurant.categoryList;
     // let b=0;
        for(let i=0;i<this.restaurant.ListDay.length;i++)
        {
          if(i==new Date().getDay())
          {
            // if(new Date().getHours()<=5)
            // {
            //   if(i>1)
            //   {
            //  i=i-1
            //  b=1;
            //   }
            //   else
            //   {
            //   i=7;
            //   b=7;
            //   }
            // }
        //    if(this.restaurant.ListDay[i].DayTime!='' && this.restaurant.ListDay[i].DayTime!='0:00-0:00' && this.restaurant.ListDay[i].DayTime!='00:00-00:00')
       //  {
        
       //    var fields = this.restaurant.ListDay[i].DayTime.split('-');
         //  if(this.proxy.isOpen(fields[0],fields[1],b))
            this.dayTime=this.restaurant.ListDay[i].DayTime;
            i=this.restaurant.ListDay.length;
           // else
           // this.dayTime="סגור";
        // }
         // else
         // this.dayTime="סגור";
          }
        }
        if(localStorage.PRICE>0)
        this.totalAmount=Number(localStorage.PRICE);
        if(this.totalAmount>0)
        this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
        else
        this.priceTotal="0 - פריטים";
        this.dataService.price.next(this.priceTotal);
        this.changePrice.emit();
        localStorage.PRICE_TOTAL=this.priceTotal;
        this.aboutRestaurant=[
          {
            "aboutRestaurantId": 1,
            "aboutRestaurantName":"בשרי",
            "isCheck": this.restaurant.IsMeaty,
          },
          {
            "aboutRestaurantId": 2,
            "aboutRestaurantName": "אסייתי",
            "isCheck": this.restaurant.IsAsian,
          },
          {
            "aboutRestaurantId": 3,
            "aboutRestaurantName":  "כשר",
            "isCheck": this.restaurant.Kasher,
          },
        ];
        //if(this.restaurant.Rate!=null)
       // this.Rate=this.restaurant.Rate;
       // else
       // if(this.restaurant.Rate==0 ||this.restaurant.Rate==null)
       // this.restaurant.Rate=3;
       if(this.restaurant.Name!=null)
      this.Name=this.restaurant.Name;
      }
      //else{
       // localStorage.ID=localStorage.ID_CATEGORY;
        //this.proxy.showError("שגיאה בקבלת פרטים ","");
     // }
     
  if(this.restaurantItem!=null && this.restaurantItem.length>0)
  this.buttonText="המשך לתשלום"
      this.foodItem=[
        {
          "price": 5,
      "foodName": "מרק מיסו",
      "amount": 2,
        },
        {
          "price": 7,
      "foodName": "קריספי קלמרי",
      "amount": 1,
        },
      ]
      if(this.restaurant!=null)
    {
      // this.aboutRestaurant=[
      //   {
      //     "aboutRestaurantId": 7,
      //     "aboutRestaurantName":"בשרי",
      //     "isCheck": this.restaurant.IsMeaty,
      //   },
      //   {
      //     "aboutRestaurantId": 7,
      //     "aboutRestaurantName": "אסייתי",
      //     "isCheck": this.restaurant.IsAsian,
      //   },
      //   {
      //     "aboutRestaurantId": 7,
      //     "aboutRestaurantName":  "כשר",
      //     "isCheck": this.restaurant.Kasher,
      //   },
      // ];
    }
  //  else{
     // localStorage.ID=localStorage.ID_CATEGORY;
  
   // }
  //  });
  });
    }
    if(this.orderService.order!=null )
    {
     if(this.bussinssService.restaurant!=null) 
     {
      this.restaurant=this.bussinssService.restaurant; 
      if( this.bussinssService.restaurant!=null && this.bussinssService.restaurant.MenuList!=null )
      this.restaurantItem=this.bussinssService.restaurant.MenuList;
      if(this.restaurant.LongImage!=null)
      this.Image=this.restaurant.LongImage;
    
      this.categores=this.restaurant.categoryList;
      // for(let j=0;j<this.categores.length;j++)
      // {
      //   this.categores[j].isCheck=0;
      // }
      if(localStorage.CATEGORY_FILTER>-1 && Number(this.categores.length-1)>localStorage.CATEGORY_FILTER)
      
      this.categores[localStorage.CATEGORY_FILTER].isCheck=1;
      let category=null;
      if(this.categores[localStorage.CATEGORY_FILTER]!=null && this.categores[localStorage.CATEGORY_FILTER]!=undefined  && this.categores[localStorage.CATEGORY_FILTER].Category!="")
      category=this.categores[localStorage.CATEGORY_FILTER].Category;
      if(this.restaurant.ID!=undefined)
        //פעם 1??
      this.bussinssService.getRestaurantDetails(this.restaurant.ID,false,false,false,category,null).subscribe((res:any)=>{
        this.restaurant=res;
        if(this.restaurant.MenuList.length>0)
       // {
          this.restaurantItem=this.restaurant.MenuList;
         // this.orderService.getOrderDetails(false).subscribe((res:any)=>{
            this.order=this.orderService.order;
     
         if(this.restaurant.MenuList.length>0)
         {
           for(let i=0;i<this.order.list.length;i++)
           {
             for(let j=0;j<this.restaurantItem.length;j++)
             {
             if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
             if(this.order.list[i].Count>0)
             this.restaurantItem[j].Rate+=this.order.list[i].Count;
             }
           }
           let amount=0;
              for(let j=0;j<this.restaurantItem.length;j++)
       {
        
        if(this.restaurantItem[j].IsAddition==1)
        {
        this.restaurantItem[j].ChangeFood=[];
        if(this.restaurantItem[j].ChangeFood==null)
           this.restaurantItem[j].ChangeFood=[];
       for(let q=0;q<this.restaurantItem[j].Rate;q++)
       {
        let changeFood = new ChangeFood();
      
        if(this.restaurantItem[j].Rate!=null)
           amount+=1;
           //this.restaurantItem[j].Rate;
        // changeFood.Name =(Number(j+1))+'-'+ this.restaurantItem[j].Name;
         changeFood.Text="לחץ לשינויים ";
        changeFood.toppings =null; 

          changeFood.Name =(Number(q+1))+'-'+ this.restaurantItem[j].Name;
          this.restaurantItem[j].ChangeFood.push(changeFood);
       }
      }
     }
           
          // this.restaurantItem=this.restaurant.MenuList;
          
          if(localStorage.PRICE>0)
          this.totalAmount=Number(localStorage.PRICE)
          else
          {
           this.totalAmount=0;
         for(let i=0;i<this.restaurant.MenuList.length;i++)
         {
           if(this.restaurant.MenuList[i].Rate>0)
           {
             let p:number=0;
             p=this.restaurant.MenuList[i].Price;
             this.totalAmount+=Number(this.restaurantItem[i].Rate)*Number(p);
             this.totalItems+=this.restaurantItem[i].Rate;
           }
         }
       }
         if(this.totalAmount>0)
         this.priceTotal=this.clickToPay+""+this.order.TotalPrice+" "+"₪";
         else
         this.priceTotal="0 - פריטים";
         this.dataService.price.next(this.priceTotal);
         this.changePrice.emit();
         localStorage.PRICE_TOTAL=this.priceTotal;
       }
         if(this.order!=null && this.order.OrderID!=0)
           {
             let haveList=false;
             let pos=-1;
             for(let j=0;j<this.restaurantItem.length;j++)
           
           {
              haveList=false;
              pos=-1;
              for(let i=0;i<this.order.list.length;i++)
             {
         
             if(this.restaurantItem[j].Rate>0)
             {
               pos=j;
             if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
             {
                haveList=true;
                i=this.order.list.length;
             }
           }
             }
             if(pos>0 && haveList==false)
             {
             this.restaurantItem[pos].Rate=0;
             let location = this.restaurantItem[pos].ChangeFood.length-1;
             this.restaurantItem[pos].ChangeFood.splice(this.restaurantItem.length-1,1);
             delete this.restaurantItem[pos].ChangeFood[location]; 
             this.restaurantItem[pos].ChangeFood.length=location;
             let p:number=0;
             p=this.restaurantItem[pos].Price;
             this.totalAmount-=Number(this.restaurantItem[pos].Rate)*Number(p);
             this.totalItems-=this.restaurantItem[pos].Rate;
             localStorage.PRICE= Number(this.totalAmount);
             if(this.totalAmount>0)
             {
               if(this.order!=null && this.order.TotalPrice!=null)
               {
             this.priceTotal=this.clickToPay+""+this.order.TotalPrice+" "+"₪";
             localStorage.PRICE_TOTAL=this.priceTotal;
             this.dataService.price.next(this.priceTotal);
             this.changePrice.emit();
             }
           
             this.totalAmount=Number(this.order.TotalPrice);
           }
           }
           }
           }
      // });
      });
    }
    
    if(this.restaurant!=null)
    {
      for(let i=0;i<this.restaurant.ListDay.length;i++)
      {
        if(i==new Date().getDay())
        {
            this.dayTime=this.restaurant.ListDay[i].DayTime;
            i=this.restaurant.ListDay.length
        }
      }
      if(localStorage.PRICE>0)
      this.totalAmount=Number(localStorage.PRICE);
      if(this.totalAmount>0)
      this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
      else
      this.priceTotal="0 - פריטים";
      this.dataService.price.next(this.priceTotal);
      this.changePrice.emit();
      localStorage.PRICE_TOTAL=this.priceTotal;
      this.aboutRestaurant=[
        {
          "aboutRestaurantId": 1,
          "aboutRestaurantName":"בשרי",
          "isCheck": this.restaurant.IsMeaty,
        },
        {
          "aboutRestaurantId": 2,
          "aboutRestaurantName": "אסייתי",
          "isCheck": this.restaurant.IsAsian,
        },
        {
          "aboutRestaurantId": 3,
          "aboutRestaurantName":  "כשר",
          "isCheck": this.restaurant.Kasher,
        },
      ];
     if(this.restaurant.Name!=null)
    this.Name=this.restaurant.Name;
     if(this.order!=null && this.order.TotalPrice!=null)
     {
    this.priceTotal=this.clickToPay+""+this.order.TotalPrice+" "+"₪";
    this.dataService.price.next(this.priceTotal);
    this.changePrice.emit();
    localStorage.PRICE_TOTAL=this.priceTotal;
     }
    }
    this.endToppings = this.dataService.notEndToppings
    if(this.endToppings==true)
    {
      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
      let pos=this.order.list.length-1;
      this.orderService.addToBagOrder(this.order.list[pos].ID,this.order.OrderID,this.order.list[pos].MenuNumber).subscribe((res:any)=>{
        this.orderService.getOrderDetails(false).subscribe((res:any)=>{
          this.order=res;
          if(this.order.TotalPrice=="0" || this.order.TotalPrice==null)
          {
          this.priceTotal="0 - פריטים";
          this.totalItems=0;
          this.totalAmount=0;
          localStorage.PRICE_TOTAL=this.priceTotal;
          localStorage.PRICE=0;
          this.dataService.price.next(this.priceTotal);
         this.changePrice.emit();
          }
          this.dataService.notEndToppings=false;
          this.restaurantItem[Number(localStorage.pos)].Rate=0;
          this.ngOnInit();
        });
      });
    }); 
    }
if(this.restaurantItem!=null && this.restaurantItem.length>0)
this.buttonText="המשך לתשלום"
    this.foodItem=[
      {
        "price": 5,
    "foodName": "מרק מיסו",
    "amount": 2,
      },
      {
        "price": 7,
    "foodName": "קריספי קלמרי",
    "amount": 1,
      },
    ]
    if(this.restaurant!=null)
  {
  }
  }
  this.text = this.dataService.search.getValue() || {};
  this.dataService.search.subscribe(data=>{
    this.text=data;
  
  if(this.text!="")
  {
    if(sessionStorage.CATEGORY_FILTER!="")
    this.categoryFilter=sessionStorage.CATEGORY_FILTER;
    else
    this.categoryToFilter="";
    if(localStorage.ID!="0")
    if(this.categoryToFilter=="")
      this.id=localStorage.ID;
      else
      this.id=this.restaurant.ID;
       if(this.id==undefined)
       this.id=this.restaurant.ID;
       let id_:number=localStorage.ID
       if(id_ !=undefined &&   localStorage.TEXT_SEARCH!="")
    this.bussinssService.getBusinessMenuBySearch(id_,this.text,1).subscribe((res:any[])=>{
      this.restaurant.MenuList=res;
      if(this.restaurant.MenuList!=null)
      {
      this.restaurantItem=this.restaurant.MenuList;
    
      this.dataService.text.next("");
      this.changText.emit();
      }
      else
      {
      this.proxy.showError("לא נמצאו מנות\n בקטגוריה זו","");
      this.dataService.text.next("");
      this.changText.emit();
      }
    });

  }
});
    }
  }

  getRestaurantDetails(restaurant,num)
  {
      this.restaurant=restaurant;
      this.restaurantItem=this.restaurant.MenuList;
    this.categores=this.restaurant.categoryList;
    let cc=0;
    if(this.categoryToFilter=="")
    cc=1;
    this.categoryToFilter=this.categores[0].Category;
 let pos:number=Number(sessionStorage.CATEGORY_FILTER);
if( pos>-1 && Number(this.categores.length-1)>= pos)
  
this.categoryToFilter=this.categores[pos].Category;
if(num>-1)
{
  let b=0;
  for(let i=0;i<this.restaurant.ListDay.length;i++)
  {
    if(i==new Date().getDay())
    {
    if(new Date().getHours()<=5)
    {
       if(i>0)
       {
      i=i-1
      b=1;
       }
       else
       {
       i=6;
       b=7;
       }
    }

  if(this.restaurant.ListDay[i].DayTime!='' && this.restaurant.ListDay[i].DayTime!='0:00-0:00' && this.restaurant.ListDay[i].DayTime!='00:00-00:00')
   {
     var fields = this.restaurant.ListDay[i].DayTime.split('-');
     if(this.proxy.isOpen(fields[0],fields[1],b))
      this.dayTime=this.restaurant.ListDay[i].DayTime;
      else
      this.dayTime="סגור";
   }
    else
    this.dayTime="סגור";
    if(this.dayTime=="סגור")
  {
    this.proxy.showError("המסעדה סגורה\n אין אפשרות לבצע הזמנה","");
    this.router.navigate(["../mainHome"])
  }
    i=this.restaurant.ListDay.length;
    }
  }
    this.category(pos);
    this.showProgress=false; 
  }

  else
  {
    this.showProgress=false;   
    if(this.restaurant.LongImage!=null)
    this.Image=this.restaurant.LongImage;
{
 if(this.restaurant.MenuList!=null)
    this.restaurantItem=this.restaurant.MenuList; 
    if(this.restaurantItem!=null)
    for(let i=0;i<this.restaurantItem.length;i++)
      {
        if(this.restaurantItem[i].Rate==undefined)
        this.restaurantItem[i].Rate=0;
        else if(this.restaurantItem[i].Rate>0)
        {
          this.buttonText="המשך לתשלום";
        this.totalItems+=this.restaurantItem[i].Rate;
       let p=0.0;
       p=this.restaurantItem[i].Price;
       this.totalAmount=Number(this.totalItems)*Number(p);
        }
      }}
      if(this.restaurant!=null)
      {
      let b=0;
        for(let i=0;i<this.restaurant.ListDay.length;i++)
        {
          if(i==new Date().getDay())
          {
          if(new Date().getHours()<=5)
          {
             if(i>0)
             {
            i=i-1
            b=1;
             }
             else
             {
             i=6;
             b=7;
             }
          }
      
            if(this.restaurant.ListDay[i].DayTime!='' && this.restaurant.ListDay[i].DayTime!='0:00-0:00' && this.restaurant.ListDay[i].DayTime!='00:00-00:00')
         {
           var fields = this.restaurant.ListDay[i].DayTime.split('-');
           if(this.proxy.isOpen(fields[0],fields[1],b))
            this.dayTime=this.restaurant.ListDay[i].DayTime;
            else
            this.dayTime="סגור";
         }
          else
          this.dayTime="סגור";
          i=this.restaurant.ListDay.length;
          }
        }
        if(localStorage.PRICE>0)
        this.totalAmount=Number(localStorage.PRICE);
       if(this.restaurant.Name!=null)
      this.Name=this.restaurant.Name;
      }
      else{
      }
     
  if(this.restaurantItem!=null && this.restaurantItem.length>0)
  this.buttonText="המשך לתשלום"
      if(this.restaurant!=null)
    {
    }
    else{
    }
   if(this.restaurant.MenuList.length>0)
   {
     let amount=0;
        for(let j=0;j<this.restaurantItem.length;j++)
 {
  if(this.restaurantItem[j].IsAddition==1)
 {
  this.restaurantItem[j].ChangeFood=[];
  if(this.restaurantItem[j].ChangeFood==null)
     this.restaurantItem[j].ChangeFood=[];
    
 for(let q=0;q<this.restaurantItem[j].Rate;q++)
 {
  let changeFood = new ChangeFood();

  if(this.restaurantItem[j].Rate!=null)
     amount+=1;
   changeFood.Text="לחץ לשינויים ";
  changeFood.toppings =null; 

    changeFood.Name =(Number(q+1))+'-'+ this.restaurantItem[j].Name;
    this.restaurantItem[j].ChangeFood.push(changeFood);
 }
}
}
    if(localStorage.PRICE>0)
    this.totalAmount=Number(localStorage.PRICE)
    else
    {
     this.totalAmount=0;
   for(let i=0;i<this.restaurant.MenuList.length;i++)
   {
     if(this.restaurant.MenuList[i].Rate>0)
     {
       let p:number=0;
       p=this.restaurant.MenuList[i].Price;
       this.totalAmount+=Number(this.restaurantItem[i].Rate)*Number(p);
       this.totalItems+=this.restaurantItem[i].Rate;
     }
   } 
 }
 }
   if(this.order!=null && this.order.OrderID!=0)
     {
       let haveList=false;
       let pos=-1;
       for(let j=0;j<this.restaurantItem.length;j++)
     
     {
        haveList=false;
        pos=-1;
        for(let i=0;i<this.order.list.length;i++)
       {
   
       if(this.restaurantItem[j].Rate>0)
       {
         pos=j;
       if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
       {
          haveList=true;
          i=this.order.list.length;
       }
     }
       }
       if(pos>0 && haveList==false)
       {
       this.restaurantItem[pos].Rate=0;
       let location = this.restaurantItem[pos].ChangeFood.length-1;
       this.restaurantItem[pos].ChangeFood.splice(this.restaurantItem.length-1,1);
       delete this.restaurantItem[pos].ChangeFood[location]; 
       this.restaurantItem[pos].ChangeFood.length=location;
       let p:number=0;
       p=this.restaurantItem[pos].Price;
       this.totalAmount-=Number(this.restaurantItem[pos].Rate)*Number(p);
       this.totalItems-=this.restaurantItem[pos].Rate;
       localStorage.PRICE= Number(this.totalAmount);
     }
     }
     }}
this.endToppings = this.dataService.notEndToppings
  if(this.endToppings==true)
  {
   
    this.orderService.getOrderDetails(false).subscribe((res:any)=>{
      this.order=res;
    let pos=this.order.list.length-1;
    this.orderService.addToBagOrder(this.order.list[pos].ID,this.order.OrderID,this.order.list[pos].MenuNumber).subscribe((res:any)=>{
      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
        this.order=res;
        this.showProgress=false;
        this.dataService.notEndToppings=false;
        this.restaurantItem[Number(localStorage.pos)].Rate=0;
        this.ngOnInit();
  
      });
    });
  }); 
  }
this.text = this.dataService.search.getValue() || {};
this.dataService.search.subscribe(data=>{
  this.text=data;

if(this.text!="")
{
  if(sessionStorage.CATEGORY_FILTER!="")
  this.categoryFilter=sessionStorage.CATEGORY_FILTER;
  else
  this.categoryToFilter="";
  if(localStorage.ID!="0")
  if(this.categoryToFilter=="")
    this.id=localStorage.ID;
    else
    this.id=this.restaurant.ID;
     if(this.id==undefined)
     this.id=this.restaurant.ID;
     let id_:number=localStorage.ID
     if(id_ !=undefined &&   localStorage.TEXT_SEARCH!="")
  this.bussinssService.getBusinessMenuBySearch(id_,this.text,1).subscribe((res:any[])=>{
    this.restaurant.MenuList=res;
    if(this.restaurant.MenuList!=null)
    {
    this.restaurantItem=this.restaurant.MenuList;
    //this.category(-1);
    this.dataService.text.next("");
    this.changText.emit();
    }
    else
    {
    this.proxy.showError("לא נמצאו מנות\n בקטגוריה זו","");
    this.dataService.text.next("");
    this.changText.emit();
    }
  });

}
});

  }

  onBackPress()
  {
    //localStorage.ID=localStorage.ID_CATEGORY;
     this.orderService.getOrderDetails(false).subscribe((res:any)=>{
       this.order=res;
      if(this.order!=null && this.order.list.length==0)
      {
        this.orderService.setUpdateOrder(this.order.OrderID,this.order.IsDelivery,this.order.IsPickup,this.order.IsSitting,1,
          null,null,0,null,1,null,null,null,null,null,null,null,null).subscribe((res:any)=>{
    this.router.navigate(["../restaurantCategory"]);
          });
        }
        else
        this.router.navigate(["../restaurantCategory"]);
     });
  }

//   payment()
//   {
//     this.restaurant.MenuList=this.restaurantItem;
//     this.orderService.restaurant=this.restaurant;
//     if(this.totalAmount>0)
//     {
//       let canContinue = true;
//            this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//        this.order=res;
//      // this.order=this.orderService.order;
//       if(this.order!=null && this.order.list!=null)
//       for(let i=0;i<this.order.list.length;i++)
//       {
//         let orderList=this.order.list[i];
//           for(let j=0;j<this.restaurant.MenuList.length;j++) {
//               let categoryItemFood = this.restaurant.MenuList[j];
//               if (orderList.IsAddition == 1)
//                   if (orderList.MenuID == categoryItemFood.ID)
//                       if(categoryItemFood.IsAdditionRequired==1) {
                  
//                           if (orderList.listAddition.length==0) {
//                               canContinue = false;
//                               j=this.restaurant.MenuList.length;
//                           }
//                       }
//           }
//       }
//       if(canContinue==true)
//       {
//     this.router.navigate(["../yourOrder"]);
//     localStorage.SHOW=false;
//       }
//     else
//     this.proxy.showError("לא בחרת \n את כל תוספות החובה","");
//     });
// }
//     else
//     this.proxy.showError("ההזמנה ריקה","");
//   }

payment()
{
  // let page=location.href;
  // let a=page.split('/')[3]
  let a = location.pathname;

  if(a.startsWith("/restaurant"))
  {
    this.showProgress=true;
    this.orderService.restaurant=this.bussinssService.restaurant;
  if(this.priceTotal!="0 - פריטים")
  {
    let canContinue = true;
         this.orderService.getOrderDetails(false).subscribe((res:any)=>{
     this.order=res;
   // this.order=this.orderService.order;
    if(this.order!=null && this.order.list!=null)
    for(let i=0;i<this.order.list.length;i++)
    {
      let orderList=this.order.list[i];
       // for(let j=0;j<this.restaurant.MenuList.length;j++) {
          //  let categoryItemFood = this.restaurant.MenuList[j];
            if (orderList.IsAdditionRequired == 1)
            canContinue = false;
            if(orderList.listAddition.length>0)
            canContinue = true;
                //if (orderList.MenuID == categoryItemFood.ID)
                  //  if(categoryItemFood.IsAdditionRequired==1) {
                
                    //    if (orderList.listAddition.length==0) {
                          
                          //  j=this.restaurant.MenuList.length;
                      //  }
                  // }
    //    }
    }
    if(canContinue==true)
    {
      this.show=false;
      this.dataService.show.next(true);
      this.changeShowPrice.emit();
      this.showProgress=false;
     // this.clickPayment=false;
    
      this.router.navigate(["../yourOrder"]);
     
    }
  else
  {
    this.showProgress=false;
  this.proxy.showError("לא בחרת \n את כל תוספות החובה","");
  }
  });
}
  else
  {
    this.showProgress=false;
  this.proxy.showError("ההזמנה ריקה","");
  }
}
else if(a=="/yourOrder")
{
// this.router.navigate(['../mainHome']);
if(localStorage.DELIVERY==0)
this.proxy.showError("עליך לבחור אחת  מאפשרויות השירות: \nמשלוח, איסוף עצמי או ישיבה במקום","");
else 
{
 
if(localStorage.NOTE=="" || localStorage.NOTE==undefined)
localStorage.NOTE=null;
let delivery=0;
let pickUp=0;
let sitting=0;
if(localStorage.DELIVERY==1)
delivery=1;
else if(localStorage.DELIVERY==2)
pickUp=1;
else if(localStorage.DELIVERY==3)
sitting=1;
if(Number(delivery)==1 && this.bussinssService.restaurant.MinimumOrder!='' && Number(this.order.TotalPrice) < Number(this.bussinssService.restaurant.MinimumOrder))
{
  this.proxy.showError("לא הגעת למינימום הזמנה של "+this.bussinssService.restaurant.MinimumOrder+" ₪"+"\n(ללא דמי משלוח)","");
}
else
{
if(this.order==null)
{
this.orderService.getOrderDetails(false).subscribe((res:any)=>{
  this.order=res;
  this.showProgress=true;
  let note=localStorage.NOTE;
  if(note!=null && note!="")
  {
      note=note.replace('####', '');
      note=note.replace('###', '');
      note=note.replace('##', '');
      note=note.replace('#', '');
}
  this.orderService.setUpdateOrder1(this.order.OrderID,Number(delivery),Number(pickUp),Number(sitting),1,note,1).subscribe((res:any)=>{
    if(res.error==false)
    {
      //localStorage.ADDRESS="";
      localStorage.paymentBy=1;
      localStorage.isClickPayment="false";
      localStorage.phone="";
      localStorage.orderName="";
      localStorage.email="";
      localStorage.street="";
      localStorage.enter="";
      localStorage.floor="";
      localStorage.numberHoush="";
      localStorage.nvCardNumber="";
      localStorage.cvv="";
      localStorage.date="";
      localStorage.identityNumber="";
      localStorage.nvCardholderName="";
          // localStorage.paymentBy="";
          localStorage.ADDRESS2="";
          localStorage.LAT2="";
          localStorage.LONG2="";
          localStorage.ADDRESS="";
          localStorage.LAT="";
          localStorage.LONG="";
          this.showProgress=false;
      if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder') 
      this.router.navigate(["../payment"]);
      else
    this.router.navigate(["../securePayment"]);
    }
    else if(res.error==true && res.responseMessage=="the delivery out of bounds")
    {
      this.showProgress=false;
    this.openOutOfBoundsDialog();
    }
    });
});
}
else if(this.order!=null &&  this.order.OrderID>0)
{
  this.showProgress=true;
  let note=localStorage.NOTE;
  if(note!=null && note!="")
  {
      note=note.replace('####', '');
      note=note.replace('###', '');
      note=note.replace('##', '');
      note=note.replace('#', '');
}
  this.orderService.setUpdateOrder1(this.order.OrderID,Number(delivery),Number(pickUp),Number(sitting),1,note,1).subscribe((res:any)=>{
    if(res.error==false)
    {
      //localStorage.ADDRESS="";
      localStorage.paymentBy=1;
      localStorage.isClickPayment="false";
      localStorage.phone="";
      localStorage.orderName="";
      localStorage.email="";
      localStorage.street="";
      localStorage.enter="";
      localStorage.floor="";
      localStorage.numberHoush="";
      localStorage.nvCardNumber="";
      localStorage.cvv="";
      localStorage.date="";
      localStorage.identityNumber="";
      localStorage.nvCardholderName="";
          // localStorage.paymentBy="";
          localStorage.ADDRESS2="";
          localStorage.LAT2="";
          localStorage.LONG2="";
          localStorage.ADDRESS="";
          localStorage.LAT="";
          localStorage.LONG="";
          this.showProgress=false;
      if(localStorage.oneTimeOrderOrOrder=='oneTimeOrder') 
      this.router.navigate(["../payment"]);
      else
    this.router.navigate(["../securePayment"]);
    }
    else if(res.error==true && res.responseMessage=="the delivery out of bounds")
    {
      this.showProgress=false;
    this.openOutOfBoundsDialog();
    }
    });
}
  
else
{
  this.showProgress=false;
// this.proxy.showError("ההזמנה שלך ריקה","");
}
}
}
}
// else
// {
//   this.show=false;
//   this.dataService.show.next(false);
//   this.changeShowPrice.emit();
//   this.router.navigate(["../yourOrder"])
// }
}

  restaurantType(i)
  {
// if(this.aboutRestaurant[i].isCheck==true)
// {
//   this.aboutRestaurant[i].isCheck=false;
//  // this.aboutRestaurantImg='assets/images/Restorant_Page_Address_Icon.png';
// }
// else{
//   {
//     this.aboutRestaurant[i].isCheck=true;
//     //this.aboutRestaurantImg='assets/images/Restorant_Page_Kosher_Icon.png';
//   }
// }
  }

  openOutOfBoundsDialog(): void {
    const dialogRef = this.matDialog.open(DialogOutOfBoundsComponent, {
      data: {name: this.name, animal: this.animal},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.matDialog.closeAll();
      this.animal = result;
    });
  }

  onScrollDown() {
    this.appendItems();
    this.direction = "down";
  }

  onUp() {
    this.sum += 20;
    this.prependItems();
    this.direction = "up";
  }

  prependItems() {
    this.addItems();
  }

  appendItems() {
    this.addItems();
  }

  addItems() {
    this.page=Number(this.page)+1;
    if(this.id>-1)
    {
      this.bussinssService.getMainList(5,this.id,this.page,Math.floor((Math.random() * 20) + 1)).subscribe((res:any[])=>{
        if(this.restaurantItem==null)
        this.restaurantItem=[];
        for(let i=0;i<res.length;i++)
        {
        this.restaurantItem.push(res[i]);
        }
    });
  }
  else
  {
    if(this.text!='')
    this.bussinssService.getMenuBySearch(this.text,this.page).subscribe((res:any[])=>{
      if(this.restaurantItem==null)
      this.restaurantItem=[];
      for(let i=0;i<res.length;i++)
      {
      this.restaurantItem.push(res[i]);
      }
    });
  }
  }

  backPress()
  {
    //window.history.back();
  }

   constructor(private route: ActivatedRoute,private dataService:DataService,private matDialog:MatDialog,private router: Router,private orderService:OrdersService,private proxy :AppProxy,private bussinssService: BussinssService) {}


   plus(MenuID:number, pos:number ,position:number)
   {
     localStorage.TEXT_SEARCH="";
     if(localStorage.ACCESS_TOKEN  != "null"  &&  localStorage.SMS_CODE!="")
     {
       {
       //this.bussinssService.restaurant=this.restaurant;
       this.totalItems+=1;
      this.restaurantItem[pos].Rate+=1;
       // let p=0.0;
       // p=this.restaurantItem[pos].Price;
       // this.totalAmount=Number(this.totalAmount)+Number(p);
       // AppComponent.price=this.totalAmount;
       // this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
       // localStorage.PRICE_TOTAL=this.priceTotal;
       // this.dataService.price.next(this.priceTotal);
       // this.changePrice.emit();
    // this.buttonText="המשך לתשלום";
     this.showChangeFood=true;
     let changeFood = new ChangeFood();
     let amount=0;
     if(this.restaurantItem[pos].Rate!=null)
        amount=this.restaurantItem[pos].Rate;
      changeFood.Name =(amount)+'-'+ this.restaurantItem[pos].Name;
      changeFood.Text="לחץ לשינויים ";
      changeFood.toppings =null; 
      if(this.restaurantItem[pos].ChangeFood==null)
        this.restaurantItem[pos].ChangeFood=[];
     {
     this.orderService.addToBagOrder_(this.restaurantItem[pos].ID,this.restaurant.ID,this.restaurantItem[pos].Rate).subscribe((res:any)=>{
      
       if(this.restaurantItem[pos].IsAddition==1)
       {    
         let changeFood = new ChangeFood();
         let amount=0;
         if(this.restaurantItem[pos].Rate!=null)
            amount=this.restaurantItem[pos].Rate;
         changeFood.Name =(amount)+'-'+ this.restaurantItem[pos].Name;
         changeFood.Text="לחץ לשינויים ";
        changeFood.toppings =null; 
         // if(this.restaurantItem[pos].ChangeFood==null)
         //   this.restaurantItem[pos].ChangeFood=[];
         this.restaurantItem[pos].ChangeFood.push(changeFood);
         
         this.restaurant.MenuList=this.restaurantItem;
     //this.bussinssService.restaurant=this.restaurant;
     if(this.orderService.restaurant==null)
     this.orderService.restaurant=this.restaurant;
     if(this.restaurant!=null && this.restaurant.MenuList!=null)
     this.orderService.restaurant.MenuList=this.restaurant.MenuList;
     localStorage.PRICE=Number(this.totalAmount);
     localStorage.ITEM=Number(this.totalItems);
     localStorage.MENU_ID=MenuID;
     localStorage.MENU_NUMBER=position;
     let a:number=1;
     position=Number(position+a);
     this.dataService.show.next(true);
      this.changeShowPrice.emit();
      localStorage.position=position;
      localStorage.pos=pos;
      localStorage.edit="false";
     //  if(this.orderService.restaurant!=null)
     //  this.orderService.restaurant.MenuList=this.restaurantItem;
     //  }
   
     if(window.innerWidth<=480)
  
          this.router.navigate(['toppings2']);
          else
          this.router.navigate(['toppings']);
      }
     
      else{
      // this.orderService.getOrderDetails(false).subscribe((res:any)=>{
         console.log("getOrderDetails3","getOrderDetails");    
       // this.order=res;
       let p=0.0;
       p=this.restaurantItem[pos].Price;
       if(this.order==undefined || this.order==null)
       {
         this.order=new MyOrder();
       this.order.TotalPrice="0";
       }
       this.totalAmount=Number(Number(this.order.TotalPrice)+Number(p));
       this.order.TotalPrice = String(this.totalAmount);
       this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
       localStorage.PRICE_TOTAL=this.priceTotal;
       this.dataService.price.next(this.priceTotal);
       this.changePrice.emit();
       if(this.orderService.restaurant!=null)
       this.orderService.restaurant.MenuList=this.restaurantItem;
    //  });
      }
     }); 
 }} 
 }
 else{
   this.proxy.showError("אינך רשום למערכת נא להרשם","");
   this.router.navigate(['../mainHome']);
 }
   }
 
   minus(i)
   {
     localStorage.TEXT_SEARCH="";
     if(this.restaurantItem[i].Rate>0)
     {
      this.totalItems=0;
      let p:number=0;
      p=this.restaurantItem[i].Price;
      this.totalAmount=(Number(this.totalAmount)- Number(p));
     this.restaurantItem[i].Rate-=1;
    if(this.totalAmount>0)
    for(let i=0;i<this.restaurantItem.length;i++)
    {
      if(Number(this.restaurantItem[i].Rate)>0)
      this.totalItems+=Number(this.restaurantItem[i].Rate);
    }
     let changeFood = new ChangeFood();
     let location:number;
     if(this.restaurantItem[i].ChangeFood!=null)
     {
     location = this.restaurantItem[i].ChangeFood.length-1;
     this.restaurantItem[i].ChangeFood.splice(this.restaurantItem.length-1,1);
     }
     this.orderService.getOrderDetails(false).subscribe((res:any)=>{
       console.log("getOrderDetails4","getOrderDetails");    
       this.order=res;
       let menuId=-1;
       for(let n=0;n<this.order.list.length;n++)
       {
 if(this.order.list[n].MenuID==this.restaurantItem[i].ID)
 menuId=n;
       }
     this.orderService.addToBagOrder(this.order.list[menuId].ID,this.order.OrderID,this.order.list[menuId].MenuNumber).subscribe((res:any)=>{
 
     if(this.restaurantItem[i].ChangeFood!=null && this.restaurantItem[i].ChangeFood.length>location)
     delete this.restaurantItem[i].ChangeFood[location]; 
     if(this.restaurantItem[i].ChangeFood!=null && this.restaurantItem[i].ChangeFood.length<0)
     this.restaurantItem[i].ChangeFood.length=location;
     if(this.restaurantItem[i].Rate==0)
     this.restaurantItem[i].ChangeFood=null;
     if(this.order.list[menuId].MenuNumber==1)
     {
      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
       console.log("getOrderDetails5","getOrderDetails");    
        this.order=res;
        if(this.order.list.length==0)
        {
     this.totalAmount=0;
     this.priceTotal="0 - פריטים";
     localStorage.PRICE=0;
     localStorage.PRICE_TOTAL=this.priceTotal;
     this.dataService.price.next(this.priceTotal);
     this.changePrice.emit();
        }
        else{
         this.totalAmount=Number(this.order.TotalPrice);
         this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
         localStorage.PRICE_TOTAL=this.priceTotal;
         this.dataService.price.next(this.priceTotal);
         this.changePrice.emit();
        }
     // if(this.order!=null)
     // this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
     //   this.restaurantItem[i].ID,this.restaurantItem[i].Amount,1,null,1,null,null,null,null,null,null).subscribe((res:any)=>{
        });
       }
       else{
         this.order.list[menuId].MenuNumber-=1;
         this.totalAmount=Number(Number(this.order.TotalPrice)-Number(this.order.list[menuId].Price));
         this.order.TotalPrice=String(this.totalAmount);
         this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
         localStorage.PRICE_TOTAL=this.priceTotal;
         this.dataService.price.next(this.priceTotal);
         this.changePrice.emit();
       }
      });
     });
   }
   }
//    plus(MenuID:number, pos:number ,position:number)
//    {
//      if(localStorage.ACCESS_TOKEN  != "null"  &&  localStorage.SMS_CODE!="")
//      {
//      // if(this.dayTime!="סגור")
//        {
//        this.bussinssService.restaurant=this.restaurant;
//        this.totalItems+=1;
//       this.restaurantItem[pos].Rate+=1;
//      //  let p=0.0;
//      //  p=this.restaurantItem[pos].Price;
//      //  this.totalAmount=Number(this.totalAmount)+Number(p);
//      // // AppComponent.price=this.totalAmount;
//      //  this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
//      //  localStorage.PRICE_TOTAL=this.priceTotal;
//      //  this.dataService.price.next(this.priceTotal);
//      //  this.changePrice.emit();
//       //AppComponent.priceTotal="לחץ לתשלום - "+this.totalAmount+" ₪";
//      this.buttonText="המשך לתשלום";
//      this.showChangeFood=true;
//      let changeFood = new ChangeFood();
//      let amount=0;
//      if(this.restaurantItem[pos].Rate!=null)
//         amount=this.restaurantItem[pos].Rate;
//       changeFood.Name =(amount)+'-'+ this.restaurantItem[pos].Name;
//       changeFood.Text="לחץ לשינויים ";
//      changeFood.toppings =null; 
//       if(this.restaurantItem[pos].ChangeFood==null)
//         this.restaurantItem[pos].ChangeFood=[];
//       //  this.restaurantItem[pos].ChangeFood.push(changeFood);
      
    
   
//      //if(this.restaurantItem[i].Amount==1)
//      {
//      this.orderService.addToBagOrder_(this.restaurantItem[pos].ID,this.restaurant.ID,this.restaurantItem[pos].Rate).subscribe((res:any)=>{
//         this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//         this.order=res;
//        let p=0.0;
//        p=this.restaurantItem[pos].Price;
//        this.totalAmount=Number(this.order.TotalPrice);
//       // AppComponent.price=this.totalAmount;
//        this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
//        localStorage.PRICE_TOTAL=this.priceTotal;
//        this.dataService.price.next(this.priceTotal);
//        this.changePrice.emit();
//        // this.orderService.getOrderAdditon(this.restaurantItem[i].ID,i,-1).subscribe((res:any)=>{
//       // this.orderAddingList=res;
//        if(this.restaurantItem[pos].IsAddition==1)
//        // this.orderAddingList!=null && this.orderAddingList.length>0)
//        {
         
//          let changeFood = new ChangeFood();
//          let amount=0;
//          if(this.restaurantItem[pos].Rate!=null)
//             amount=this.restaurantItem[pos].Rate;
//          changeFood.Name =(amount)+'-'+ this.restaurantItem[pos].Name;
//          changeFood.Text="לחץ לשינויים ";
//         changeFood.toppings =null; 
//          if(this.restaurantItem[pos].ChangeFood==null)
//            this.restaurantItem[pos].ChangeFood=[];
//          this.restaurantItem[pos].ChangeFood.push(changeFood);
//          this.restaurant.MenuList=this.restaurantItem;
//      this.bussinssService.restaurant=this.restaurant;
//      if(this.orderService.restaurant==null)
//      this.orderService.restaurant=this.restaurant;
//      if(this.restaurant!=null && this.restaurant.MenuList!=null)
//      this.orderService.restaurant.MenuList=this.restaurant.MenuList;
//      localStorage.PRICE=Number(this.totalAmount);
//      localStorage.ITEM=Number(this.totalItems);
//      //let pos=position+1;
//      localStorage.MENU_ID=MenuID;
//      localStorage.MENU_NUMBER=position;
//      let a:number=1;
//      position=Number(position+a);
//      this.dataService.show.next(true);
//       this.changeShowPrice.emit();
//       localStorage.edit="false";
//       localStorage.position=position;
//       localStorage.pos=pos;
//      this.router.navigate(['toppings2']);
//       }
//       if(this.orderService.restaurant!=null)
//       this.orderService.restaurant.MenuList=this.restaurantItem;
//      });
//    });
//  }} 
//  // else
//  //this.proxy.showError("המסעדה סגורה\n אין אפשרות לבצע הזמנה","");
//  //this.openDialog(); 
//  }
//  else{
//    this.proxy.showError("אינך רשום למערכת נא להרשם","");
//    this.router.navigate(['../mainHome']);
//  }
//    //   else if(this.restaurantItem[i].Amount>1)
//    //   this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//    //     this.order=res;
//    //     this.orderService.getOrderAdditon(this.restaurantItem[i].ID,i,-1).subscribe((res:any)=>{
//    //       this.orderAddingList=res;
//    //       if(this.orderAddingList!=null && this.orderAddingList.length>0)
//    //       {
//    //         let changeFood = new ChangeFood();
//    //         let amount=0;
//    //         if(this.restaurantItem[i].Amount!=null)
//    //            amount=this.restaurantItem[i].Amount;
//    //         changeFood.Name =(amount)+'-'+ this.restaurantItem[i].Name;
//    //         changeFood.Text="לחץ לשינויים ";
//    //        changeFood.toppings =null; 
//    //         if(this.restaurantItem[i].ChangeFood==null)
//    //           this.restaurantItem[i].ChangeFood=[];
//    //         this.restaurantItem[i].ChangeFood.push(changeFood);
//    //      }
//    //     });
//    //   {
//    //   let id=0;
//    //   if(this.order!=null)
//    //     for(let j=0;j<this.order.list.length;j++)
//    //     {
//    //       if(this.order.list[j].MenuID==this.restaurantItem[i].ID)
//    //       id=this.order.list[j].ID;
//    //     }
//    //   this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,
//    //     this.restaurantItem[i].ID,this.restaurantItem[i].Amount,1,null,1,null,null,null,null,null,null,null,null,null).subscribe((res:any)=>{
//    //     });
//    //   }
//    // });
//    }
 
//    minus(i)
//    {
//      localStorage.TEXT_SEARCH="";
//      if(this.restaurantItem[i].Rate>0)
//      {
//       this.totalItems=0;
//       let p:number=0;
//       p=this.restaurantItem[i].Price;
//       this.totalAmount=(Number(this.totalAmount)- Number(p));
//      this.restaurantItem[i].Rate-=1;
//     if(this.totalAmount>0)
//     for(let i=0;i<this.restaurantItem.length;i++)
//     {
//       if(Number(this.restaurantItem[i].Rate)>0)
//       this.totalItems+=Number(this.restaurantItem[i].Rate);
//     }
//      let changeFood = new ChangeFood();
//      let location:number;
//      if(this.restaurantItem[i].ChangeFood!=null)
//      {
//      location = this.restaurantItem[i].ChangeFood.length-1;
//      this.restaurantItem[i].ChangeFood.splice(this.restaurantItem.length-1,1);
//      }
//      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//        console.log("getOrderDetails4","getOrderDetails");    
//        this.order=res;
//        let menuId=-1;
//        for(let n=0;n<this.order.list.length;n++)
//        {
//  if(this.order.list[n].MenuID==this.restaurantItem[i].ID)
//  menuId=n;
//        }
//      this.orderService.addToBagOrder(this.order.list[menuId].ID,this.order.OrderID,this.order.list[menuId].MenuNumber).subscribe((res:any)=>{
 
//      if(this.restaurantItem[i].ChangeFood!=null && this.restaurantItem[i].ChangeFood.length>location)
//      delete this.restaurantItem[i].ChangeFood[location]; 
//      if(this.restaurantItem[i].ChangeFood!=null && this.restaurantItem[i].ChangeFood.length<0)
//      this.restaurantItem[i].ChangeFood.length=location;
//      if(this.restaurantItem[i].Rate==0)
//      this.restaurantItem[i].ChangeFood=null;
//      if(this.order.list[menuId].MenuNumber==1)
//      {
//       this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//        console.log("getOrderDetails5","getOrderDetails");    
//         this.order=res;
//         if(this.order.list.length==0)
//         {
//      this.totalAmount=0;
//      this.priceTotal="0 - פריטים";
//      localStorage.PRICE=0;
//      localStorage.PRICE_TOTAL=this.priceTotal;
//      this.dataService.price.next(this.priceTotal);
//      this.changePrice.emit();
//         }
//         else{
//          this.totalAmount=Number(this.order.TotalPrice);
//          this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
//          localStorage.PRICE_TOTAL=this.priceTotal;
//          this.dataService.price.next(this.priceTotal);
//          this.changePrice.emit();
//         }
//      // if(this.order!=null)
//      // this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
//      //   this.restaurantItem[i].ID,this.restaurantItem[i].Amount,1,null,1,null,null,null,null,null,null).subscribe((res:any)=>{
//         });
//        }
//        else{
//          this.order.list[menuId].MenuNumber-=1;
//          this.totalAmount=Number(Number(this.order.TotalPrice)-Number(this.order.list[menuId].Price));
//          this.order.TotalPrice=String(this.totalAmount);
//          this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
//          localStorage.PRICE_TOTAL=this.priceTotal;
//          this.dataService.price.next(this.priceTotal);
//          this.changePrice.emit();
//        }
//       });
//      });
//    }
//    }

   category(i){
     localStorage.CATEGORY_FILTER=i;
     for(let j=0;j<this.categores.length;j++)
     {
       if(j!=i)
       this.categores[j].isCheck=0;
     }
     if(i>-1)
     {
     {
        this.categores[i].isCheck=1;
        this.categoryToFilter=this.categores[i].Category;
     }

       if(localStorage.ID!="0")
       if(this.categoryToFilter=="")
         this.id=localStorage.ID;
         else
         this.id=this.restaurant.ID;
       if(this.categoryToFilter==null)
       this.categoryToFilter="";
       this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
         this.restaurant=res;
         this.orderService.restaurant=this.restaurant;
         if(this.restaurant.LongImage!=null)
         this.Image=this.restaurant.LongImage;
         this.restaurantItem=this.restaurant.MenuList;
         if(this.restaurantItem!=null)
         for(let i=0;i<this.restaurantItem.length;i++)
           {
             if(this.restaurantItem[i].Rate==undefined)
             this.restaurantItem[i].Rate=0;
             else if(this.restaurantItem[i].Rate>0)
             {
               this.buttonText="המשך לתשלום";
             this.totalItems+=this.restaurantItem[i].Rate;
            let p=0.0;
            p=this.restaurantItem[i].Price;
            this.totalAmount=Number(this.totalItems)*Number(p);
            if(this.totalAmount>0)
         this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
         else
         this.priceTotal="0 - פריטים";
         localStorage.PRICE_TOTAL=this.priceTotal;
             }
           }
          // this.orderService.getOrderDetails(false).subscribe((res:any)=>{
             this.order=this.orderService.order;
      
          if(this.restaurant.MenuList.length>0)
          {
            for(let i=0;i<this.order.list.length;i++)
            {
              for(let j=0;j<this.restaurantItem.length;j++)
              {
              if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
              if(this.order.list[i].Count>0)
              this.restaurantItem[j].Rate+=this.order.list[i].Count;
              }
            }
            let amount=0;
            
               for(let j=0;j<this.restaurantItem.length;j++)
        {
         if(this.restaurantItem[j].IsAddition==1)
         {
         this.restaurantItem[j].ChangeFood=[];
         if(this.restaurantItem[j].ChangeFood==null)
            this.restaurantItem[j].ChangeFood=[];
        for(let q=0;q<this.restaurantItem[j].Rate;q++)
        {
         let changeFood = new ChangeFood();
       
         if(this.restaurantItem[j].Rate!=null)
            amount+=1;
            //this.restaurantItem[j].Rate;
         // changeFood.Name =(Number(j+1))+'-'+ this.restaurantItem[j].Name;
          changeFood.Text="לחץ לשינויים ";
         changeFood.toppings =null; 
 
           changeFood.Name =(Number(q+1))+'-'+ this.restaurantItem[j].Name;
           this.restaurantItem[j].ChangeFood.push(changeFood);
        }
       }
      }
            
           // this.restaurantItem=this.restaurant.MenuList;
           
           if(localStorage.PRICE>0)
           this.totalAmount=Number(localStorage.PRICE)
           else
           {
            this.totalAmount=0;
          for(let i=0;i<this.restaurant.MenuList.length;i++)
          {
            if(this.restaurant.MenuList[i].Rate>0)
            {
              let p:number=0;
              p=this.restaurant.MenuList[i].Price;
              this.totalAmount+=Number(this.restaurantItem[i].Rate)*Number(p);
              this.totalItems+=this.restaurantItem[i].Rate;
            }
          }
        }
      //   for(let j=0;j<this.restaurantItem.length;j++)
      //   {
      //   let changeFood = new ChangeFood();
      //   let amount=0;
      //   if(this.restaurantItem[j].Rate!=null)
      //      amount=this.restaurantItem[j].Rate;
      //    changeFood.Name =(amount)+'-'+ this.restaurantItem[j].Name;
      //    changeFood.Text="לחץ לשינויים ";
      //   changeFood.toppings =null; 
      //   this.restaurantItem[j].ChangeFood=[];
      //   for(let q=0;q<this.restaurantItem[j].Rate;q++)
      //   {
      //   if(this.restaurantItem[j].ChangeFood==null)
      //      this.restaurantItem[j].ChangeFood=[];
      //      this.restaurantItem[j].ChangeFood.push(changeFood);
      //   }
      // }
          if(this.totalAmount>0)
          this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
          else
          this.priceTotal="0 - פריטים";
         //  this.dataService.price.next(this.priceTotal);
         //  this.changePrice.emit();
          localStorage.PRICE_TOTAL=this.priceTotal;
        }
         // if( this.orderService.restaurant!=null && this.orderService.restaurant.MenuList!=null )
         // this.restaurantItem=this.orderService.restaurant.MenuList;
          //if(this.orderService.restaurant.categoryList!=null)
         // this.categores=this.restaurant.categoryList;
          if(this.order!=null && this.order.OrderID!=0)
            {
              let haveList=false;
              let pos=-1;
              for(let j=0;j<this.restaurantItem.length;j++)
            
            {
               haveList=false;
               pos=-1;
               for(let i=0;i<this.order.list.length;i++)
              {
          
              if(this.restaurantItem[j].Rate>0)
              {
                pos=j;
              if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
              {
                 haveList=true;
                 i=this.order.list.length;
              }
            }
              }
              if(pos>0 && haveList==false)
              {
              this.restaurantItem[pos].Rate=0;
              let location = this.restaurantItem[pos].ChangeFood.length-1;
              this.restaurantItem[pos].ChangeFood.splice(this.restaurantItem.length-1,1);
              delete this.restaurantItem[pos].ChangeFood[location]; 
              this.restaurantItem[pos].ChangeFood.length=location;
              let p:number=0;
              p=this.restaurantItem[pos].Price;
              this.totalAmount-=Number(this.restaurantItem[pos].Rate)*Number(p);
              this.totalItems-=this.restaurantItem[pos].Rate;
              localStorage.PRICE= Number(this.totalAmount);
              if(this.totalAmount>0)
              {
                if(this.order!=null && this.order.TotalPrice!=null)
                {
              this.priceTotal=this.clickToPay+""+this.order.TotalPrice+" "+"₪";
              localStorage.PRICE_TOTAL=this.priceTotal;
              this.dataService.price.next(this.priceTotal);
              this.changePrice.emit();
              }
            
              this.totalAmount=Number(this.order.TotalPrice);
            }
            }
            }
            // for(let i=0;i<this.order.list.length;i++)
            // {
            //   for(let j=0;j<this.restaurantItem.length;j++)
            //   {
            //   if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
            //   if(this.order.list[i].Count>0)
            //   this.restaurantItem[j].Amount=this.order.list[i].Count;
            //   }
            // }
              
      
          //  if(haveList==false)
           // this.restaurantItem[pos].Amount=0;
            }
            if(this.order!=null && this.order.TotalPrice!=null)
            {
          this.priceTotal=this.clickToPay+""+this.order.TotalPrice+" "+"₪";
          localStorage.PRICE_TOTAL=this.priceTotal;
          this.dataService.price.next(this.priceTotal);
          this.changePrice.emit();
          }
      //  });
         
       });
     }
  
    }
  
//    plus(MenuID:number, pos:number ,position:number)
//   {
//     if(localStorage.ACCESS_TOKEN  != "null"  &&  localStorage.SMS_CODE!="")
//     {
//      if(this.dayTime!="סגור")
//       {
//       this.bussinssService.restaurant=this.restaurant;
//       this.totalItems+=1;
//      this.restaurantItem[pos].Rate+=1;
//     //  let p=0.0;
//     //  p=this.restaurantItem[pos].Price;
//     //  this.totalAmount=Number(this.totalAmount)+Number(p);
//     // // AppComponent.price=this.totalAmount;
//     //  this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
//     //  localStorage.PRICE_TOTAL=this.priceTotal;
//     //  this.dataService.price.next(this.priceTotal);
//     //  this.changePrice.emit();
//      //AppComponent.priceTotal="לחץ לתשלום - "+this.totalAmount+" ₪";
//     this.buttonText="המשך לתשלום";
//     this.showChangeFood=true;
//     let changeFood = new ChangeFood();
//     let amount=0;
//     if(this.restaurantItem[pos].Rate!=null)
//        amount=this.restaurantItem[pos].Rate;
//      changeFood.Name =(amount)+'-'+ this.restaurantItem[pos].Name;
//      changeFood.Text="לחץ לשינויים ";
//     changeFood.toppings =null; 
//      if(this.restaurantItem[pos].ChangeFood==null)
//        this.restaurantItem[pos].ChangeFood=[];
//      //  this.restaurantItem[pos].ChangeFood.push(changeFood);
     
   
  
//     //if(this.restaurantItem[i].Amount==1)
//     {
//     this.orderService.addToBagOrder_(this.restaurantItem[pos].ID,this.restaurant.ID,this.restaurantItem[pos].Rate).subscribe((res:any)=>{
//       this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//         this.order=res;
//         this.totalAmount=Number(this.order.TotalPrice);
//         // AppComponent.price=this.totalAmount;
//          this.priceTotal=this.clickToPay+" "+this.totalAmount+" ₪";
//          localStorage.PRICE_TOTAL=this.priceTotal;
//          this.dataService.price.next(this.priceTotal);
//          this.changePrice.emit();
//       // this.orderService.getOrderAdditon(this.restaurantItem[i].ID,i,-1).subscribe((res:any)=>{
//      // this.orderAddingList=res;
//       if(this.restaurantItem[pos].IsAddition==1)
//       // this.orderAddingList!=null && this.orderAddingList.length>0)
//       {
        
//         let changeFood = new ChangeFood();
//         let amount=0;
//         if(this.restaurantItem[pos].Rate!=null)
//            amount=this.restaurantItem[pos].Rate;
//         changeFood.Name =(amount)+'-'+ this.restaurantItem[pos].Name;
//         changeFood.Text="לחץ לשינויים ";
//        changeFood.toppings =null; 
//         if(this.restaurantItem[pos].ChangeFood==null)
//           this.restaurantItem[pos].ChangeFood=[];
//         this.restaurantItem[pos].ChangeFood.push(changeFood);
//         this.restaurant.MenuList=this.restaurantItem;
//     this.bussinssService.restaurant=this.restaurant;
//     if(this.orderService.restaurant==null)
//     this.orderService.restaurant=this.restaurant;
//     if(this.restaurant!=null && this.restaurant.MenuList!=null)
//     this.orderService.restaurant.MenuList=this.restaurant.MenuList;
//     localStorage.PRICE=Number(this.totalAmount);
//     localStorage.ITEM=Number(this.totalItems);
//     //let pos=position+1;
//     localStorage.MENU_ID=MenuID;
//     localStorage.MENU_NUMBER=position;
//     let a:number=1;
//     position=Number(position+a);
//     this.dataService.show.next(true);
//      this.changeShowPrice.emit();
//     this.router.navigate(['../toppings',{position,pos}]);
//      }
//      if(this.orderService.restaurant!=null)
//      this.orderService.restaurant.MenuList=this.restaurantItem;
//     });
//   });
// }} 
//  else
// this.proxy.showError("המסעדה סגורה\n אין אפשרות לבצע הזמנה","");
// }
// else{
//   this.proxy.showError("אינך רשום למערכת נא להרשם","");
//   this.router.navigate(['../mainHome']);
// }
//   //   else if(this.restaurantItem[i].Amount>1)
//   //   this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//   //     this.order=res;
//   //     this.orderService.getOrderAdditon(this.restaurantItem[i].ID,i,-1).subscribe((res:any)=>{
//   //       this.orderAddingList=res;
//   //       if(this.orderAddingList!=null && this.orderAddingList.length>0)
//   //       {
//   //         let changeFood = new ChangeFood();
//   //         let amount=0;
//   //         if(this.restaurantItem[i].Amount!=null)
//   //            amount=this.restaurantItem[i].Amount;
//   //         changeFood.Name =(amount)+'-'+ this.restaurantItem[i].Name;
//   //         changeFood.Text="לחץ לשינויים ";
//   //        changeFood.toppings =null; 
//   //         if(this.restaurantItem[i].ChangeFood==null)
//   //           this.restaurantItem[i].ChangeFood=[];
//   //         this.restaurantItem[i].ChangeFood.push(changeFood);
//   //      }
//   //     });
//   //   {
//   //   let id=0;
//   //   if(this.order!=null)
//   //     for(let j=0;j<this.order.list.length;j++)
//   //     {
//   //       if(this.order.list[j].MenuID==this.restaurantItem[i].ID)
//   //       id=this.order.list[j].ID;
//   //     }
//   //   this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,
//   //     this.restaurantItem[i].ID,this.restaurantItem[i].Amount,1,null,1,null,null,null,null,null,null,null,null,null).subscribe((res:any)=>{
//   //     });
//   //   }
//   // });
//   }

//   minus(i)
//   {
//     if(this.restaurantItem[i].Rate>0)
//     {
//      this.totalItems=0;
//      let p:number=0;
//      p=this.restaurantItem[i].Price;
//      this.totalAmount=(Number(this.totalAmount)- Number(p));
//     this.restaurantItem[i].Rate-=1;
//    // this.totalAmount-= this.restaurantItem[i].Price;
//    if(this.totalAmount>0)
//    for(let i=0;i<this.restaurantItem.length;i++)
//    {
//      if(Number(this.restaurantItem[i].Rate)>0)
//      this.totalItems+=Number(this.restaurantItem[i].Rate);
//    }
//    if(this.totalItems>0)
//    {
//     this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
//     localStorage.PRICE_TOTAL=this.priceTotal;
//     this.dataService.price.next(this.priceTotal);
//     this.changePrice.emit();
//    }
//     else
//     {
//     this.totalAmount=0;
//     this.priceTotal="0 - פריטים";
//     localStorage.PRICE=0;
//     localStorage.PRICE_TOTAL=this.priceTotal;
//     this.dataService.price.next(this.priceTotal);
//     this.changePrice.emit();
//     }
//     localStorage.PRICE_TOTAL=this.priceTotal;
//     let changeFood = new ChangeFood();
//     let location:number;
//     location = this.restaurantItem[i].ChangeFood.length-1;
//     this.restaurantItem[i].ChangeFood.splice(this.restaurantItem.length-1,1);
//     let id;
//     for(let j=0;j<this.order.list.length;j++)
//     {
//       if(this.order.list[j].MenuID==this.restaurantItem[i].ID)
//             id=this.order.list[j].ID;
//     }
//     this.orderService.addToBagOrder(this.restaurantItem[i].ID,this.restaurant.ID,0,1).subscribe((res:any)=>
//     {
//     if(this.restaurantItem[i].ChangeFood!=null && this.restaurantItem[i].ChangeFood.length>location)
//     delete this.restaurantItem[i].ChangeFood[location]; 
//     if(this.restaurantItem[i].ChangeFood!=null  )
//     this.restaurantItem[i].ChangeFood.length=location;
//     if(this.restaurantItem[i].Rate==0)
//     this.restaurantItem[i].ChangeFood=null;
//      this.orderService.getOrderDetails(false).subscribe((res:any)=>{
//        this.order=res;
//        if(this.order.TotalPrice!="0" && this.order.TotalPrice!=null)
//        this.priceTotal=this.clickToPay+""+this.order.TotalPrice+" "+"₪";
//        else
//        this.priceTotal="0 - פריטים";
//        localStorage.PRICE_TOTAL=this.priceTotal;
//        this.dataService.price.next(this.priceTotal);
//        this.changePrice.emit();
//     // if(this.order!=null)
//     // this.orderService.setUpdateOrder(this.order.OrderID,0,0,0,1,
//     //   this.restaurantItem[i].ID,this.restaurantItem[i].Amount,1,null,1,null,null,null,null,null,null).subscribe((res:any)=>{
//        });
//      });
//   }
//   }

  changeOnFood(MenuID:number, pos:number ,position:number){
  
    this.restaurant.MenuList=this.restaurantItem;
    this.bussinssService.restaurant=this.restaurant;
    if(this.orderService.restaurant==null)
    this.orderService.restaurant=this.restaurant;
    if(this.restaurant!=null && this.restaurant.MenuList!=null)
    this.orderService.restaurant.MenuList=this.restaurant.MenuList;
    localStorage.PRICE=Number(this.totalAmount);
    localStorage.ITEM=Number(this.totalItems);
    //let pos=position+1;
    localStorage.MENU_ID=MenuID;
    localStorage.MENU_NUMBER=position;
    let a:number=1;
    let b=Number(position-1);
    let have=false;
    if(b>=0)
    for(let i=0;i<this.restaurant.MenuList[pos].ChangeFood.length;i++)
    {
    if(this.restaurant.MenuList[pos].ChangeFood[i].Name==null || this.restaurant.MenuList[pos].ChangeFood[i].Name=="" )
    have=true;
    }
    if(have==true)
    position=Number(position-1);
    position=Number(position+a);
    this.dataService.show.next(true);
     this.changeShowPrice.emit();
        localStorage.edit="false";
        localStorage.position=position;
        localStorage.pos=pos;
    this.router.navigate(['toppings2']);
  //  }
  }

  addFevorit()
  {
    if(localStorage.ACCESS_TOKEN  == "null")
    this.router.navigate(['../mainHome']);
    else
    {
    this.clickFevorite=true;
   this.bussinssService.setFavoriteBusiness(this.restaurant.ID).subscribe((res:any)=>{
     if(this.restaurant.IsFevorite==1)
     this.restaurant.IsFevorite=0;
     else
     this.restaurant.IsFevorite=1;
     // this.change.emit();
  });
}
  }

  // category(i){
  //   //this.restaurantItem=null
  //   localStorage.CATEGORY_FILTER=i;
  //   for(let j=0;j<this.categores.length;j++)
  //   {
  //     if(j!=i)
  //     this.categores[j].isCheck=0;
  //   }
  //   if(this.categores[i].isCheck==1)
  //   {
  //      this.categores[i].isCheck=0;
  //      this.categoryToFilter=null;
  //   }
  //   else
  //   {
  //      this.categores[i].isCheck=1;
  //      this.categoryToFilter=this.categores[i].Category;
  //   }
  //    //  if(this.categores[i].isCheck==1)
  //     // this.ngOnInit();
  //     if(localStorage.ID!="0")
  //     if(this.categoryToFilter=="")
  //       this.id=localStorage.ID;
  //       else
  //       this.id=this.restaurant.ID;
  //     if(this.categoryToFilter==null)
  //     this.categoryToFilter="";
  //     if(this.id!=undefined)
  //     this.bussinssService.getRestaurantDetails(this.id,false,false,false,this.categoryToFilter,null).subscribe((res:any)=>{
  //       this.restaurant=res;
  //       this.orderService.restaurant=this.restaurant;
  //       if(this.restaurant.LongImage!=null)
  //       this.Image=this.restaurant.LongImage;
  //       this.restaurantItem=this.restaurant.MenuList;
  //       if(this.restaurantItem!=null)
  //       for(let i=0;i<this.restaurantItem.length;i++)
  //         {
  //           if(this.restaurantItem[i].Rate==undefined)
  //           this.restaurantItem[i].Rate=0;
  //           else if(this.restaurantItem[i].Rate>0)
  //           {
  //             this.buttonText="המשך לתשלום";
  //           this.totalItems+=this.restaurantItem[i].Rate;
  //          let p=0.0;
  //          p=this.restaurantItem[i].Price;
  //          this.totalAmount=Number(this.totalItems)*Number(p);
  //          if(this.totalAmount>0)
  //       this.priceTotal=this.clickToPay+""+this.totalAmount+" "+"₪";
  //       else
  //       this.priceTotal="0 - פריטים";
  //       localStorage.PRICE_TOTAL=this.priceTotal;
  //           }
  //         }
  //         this.orderService.getOrderDetails(false).subscribe((res:any)=>{
  //           this.order=res;
  //         for(let i=0;i<this.order.list.length;i++)
  //         {
  //           for(let j=0;j<this.restaurantItem.length;j++)
  //           {
  //           if(this.order.list[i].MenuID==this.restaurantItem[j].ID)
  //           if(this.order.list[i].Count>0)
  //           this.restaurantItem[j].Rate+=this.order.list[i].Count;
          
  //           for(let j=0;j<this.restaurantItem.length;j++)
  //           {
  //            this.restaurantItem[j].ChangeFood=[];
  //            if(this.restaurantItem[j].ChangeFood==null)
  //               this.restaurantItem[j].ChangeFood=[];
  //           for(let q=0;q<this.restaurantItem[j].Rate;q++)
  //           {
  //            let changeFood = new ChangeFood();
          
  //               //this.restaurantItem[j].Rate;
  //            // changeFood.Name =(Number(j+1))+'-'+ this.restaurantItem[j].Name;
  //             changeFood.Text="לחץ לשינויים ";
  //            changeFood.toppings =null; 
     
  //              changeFood.Name =(Number(q+1))+'-'+ this.restaurantItem[j].Name;
  //              this.restaurantItem[j].ChangeFood.push(changeFood);
  //           }
  //         }
  //           }
  //         }});
  //     });
  //  }

   bigImage(i)
   {
      // const dialogConfig = new MatDialogConfig();
      // this.matDialog.open(BigImageComponent, { disableClose: true });
      //  this.matDialog.open(BigImageComponent, {
      //   width: '330px',
      //   height: '400px',
      //   data: {
      //     dataKey: this.restaurantItem[i].Image
      //   }
        
      // });
      let dialogRef = this.matDialog.open(BigImageComponent, {
        disableClose: true,
    });
dialogRef.componentInstance.url = this.restaurantItem[i].Image;
   }


 
  
  scrollToTop(): void {
    setTimeout(() => window.scroll(0, 0), 0);
  }
}
